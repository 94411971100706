import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import {
  faThumbsUp,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RevGuidesInsightCheet: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Insight Materials Cheat Sheet (1.4 patch)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_banners.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Insight Materials Cheat Sheet (1.4 patch)</h1>
          <h2>Best stages to farm Insight Materials in Reverse: 1999.</h2>
          <p>
            Last updated: <strong>29/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guide contains information about Insight Materials - whether you
          should farm them directly or craft them instead. Huge thanks to the
          creators of{' '}
          <a
            href="https://www.kdocs.cn/l/cd5MWeCl5bKw"
            target="_blank"
            rel="noreferrer"
          >
            this guide
          </a>{' '}
          who keep track of the best stages to farm.
        </p>
        <SectionHeader title="Purple tier materials" />
        <div className="insight-v2">
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/bifurcated_skeleton.webp"
                alt="Material"
              />
              <h5>Bifurcated Skeleton</h5>
            </div>
            <div className="information grid-2">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    4-20 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>34.10%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_fortune.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Fortune</p>
                      <p className="drop">
                        <strong>43.91%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/shattered_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Shattered Bones</p>
                      <p className="drop">
                        <strong>65.65%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_banishing.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Banishing</p>
                      <p className="drop">
                        <strong>202.24%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    3-7 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>33.59%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/liquified_terror.webp"
                        alt="Material"
                      />
                      <p className="name">Liquified Terror</p>
                      <p className="drop">
                        <strong>23.21%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/shattered_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Shattered Bones</p>
                      <p className="drop">
                        <strong>66.33%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/trembling_tooth.webp"
                        alt="Material"
                      />
                      <p className="name">Trembling Tooth</p>
                      <p className="drop">
                        <strong>56.64%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                Because Bifurcated Skeleton has a decent droprate it's better to
                farm it directly instead of crafting. Whether should you farm
                4-20 or 3-7 stage it depends on the lower tier materials you
                need.
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/biting_box.webp"
                alt="Material"
              />
              <h5>Biting Box</h5>
            </div>
            <div className="information">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    5-19 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>31.79%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop gold">
                      <StaticImage
                        src="../../../images/reverse/materials/silver_bullet.webp"
                        alt="Material"
                      />
                      <p className="name">Silver Bullet</p>
                      <p className="drop">
                        <strong>1.99%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/liquified_terror.webp"
                        alt="Material"
                      />
                      <p className="name">Liquified Terror</p>
                      <p className="drop">
                        <strong>10.09%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/silver_ore.webp"
                        alt="Material"
                      />
                      <p className="name">Silver Ore</p>
                      <p className="drop">
                        <strong>70.34%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                With the release of Chapter 5 in the 1.4 patch, it's finally
                worth farming Biting Box directly!
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/clawed_pendulum.webp"
                alt="Material"
              />
              <h5>Clawed Pendulum</h5>
            </div>
            <div className="information">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    3-11 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>31.18%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/milled_magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Milled Magnesia</p>
                      <p className="drop">
                        <strong>12.80%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Magnesia Crystal</p>
                      <p className="drop">
                        <strong>36.22%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_banishing.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Banishing</p>
                      <p className="drop">
                        <strong>66.86%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                Despite the slightly lower than usual drop rate and
                underwhelming drop rates on the other materials, farming Clawed
                Pendulum is the best thing to do - mainly because of Solidus
                (one of the required Blue tier material) being a nightmare to
                farm/craft that will force you to use much more stamina so just
                pray to RNG gods and farm Clawed Pendulum directly.
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/holy_silver.webp"
                alt="Material"
              />
              <h5>Holy Silver</h5>
            </div>
            <div className="information grid-2">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    2-3 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>16</strong> stamina | <strong>34.54%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/rough_silver_ingot.webp"
                        alt="Material"
                      />
                      <p className="name">Rough Silver Ingot</p>
                      <p className="drop">
                        <strong>3.81%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/liquified_terror.webp"
                        alt="Material"
                      />
                      <p className="name">Liquified Terror</p>
                      <p className="drop">
                        <strong>2.10%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/silver_ore.webp"
                        alt="Material"
                      />
                      <p className="name">Silver Ore</p>
                      <p className="drop">
                        <strong>166.19%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    3-8 Hard{' '}
                    <span className="tag maybe">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> A good
                      alternative
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>35.01%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/esoteric_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Esoteric Bones</p>
                      <p className="drop">
                        <strong>29.25%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/shattered_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Shattered Bones</p>
                      <p className="drop">
                        <strong>122.64%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/silver_ore.webp"
                        alt="Material"
                      />
                      <p className="name">Silver Ore</p>
                      <p className="drop">
                        <strong>63.21%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                Holy Armor is best farmed in the 2-3 stage as it also drops a
                lot of Silver Ore and has small chance to drop both Blue tier
                materials needed to craft it (and a few others) - on top of
                that, you just need 16 stamina per run, compared to the usual
                18. The 3-8 stage is an alternative that you can farm if you
                need Esoteric Bones too.
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/prophetic_bird.webp"
                alt="Material"
              />
              <h5>Prophetic Bird</h5>
            </div>
            <div className="information grid-2">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    2-6 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>16</strong> stamina | <strong>45.12%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_fortune.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Fortune</p>
                      <p className="drop">
                        <strong>6.59%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/esoteric_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Esoteric Bones</p>
                      <p className="drop">
                        <strong>3.48%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_banishing.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Banishing</p>
                      <p className="drop">
                        <strong>194.25%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    3-9 Hard{' '}
                    <span className="tag maybe">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> A good
                      alternative
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>47.52%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/rough_silver_ingot.webp"
                        alt="Material"
                      />
                      <p className="name">Rough Silver Ingot</p>
                      <p className="drop">
                        <strong>53.62%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_fortune.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Fortune</p>
                      <p className="drop">
                        <strong>5.90%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_banishing.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Banishing</p>
                      <p className="drop">
                        <strong>50.52%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                2-6 is another one node wonder. The lower stamina cost combined
                with the chance to get 2 Spell of Banishing drops per run and
                the chance to get various Blue tier materials makes it super
                good. 3-9 by comparison costs a bit more stamina, but also you
                have a really good chance to get Rough Silver Inget - so if you
                don't need the materials in 2-6, this one is a great alternative
                to farm. Both stages also have the highest chance to drop the
                Purple material in the game, so ideally, you will never even
                bother trying to craft the Prophetic Bird.
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/salted.webp"
                alt="Material"
              />
              <h5>Salted Mandrake</h5>
            </div>
            <div className="information grid-2">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    3-13 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>34.12%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/milled_magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Milled Magnesia</p>
                      <p className="drop">
                        <strong>13.15%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Magnesia Crystal</p>
                      <p className="drop">
                        <strong>68.82%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop gold">
                      <StaticImage
                        src="../../../images/reverse/materials/murmur_of_insanity.webp"
                        alt="Material"
                      />
                      <p className="name">Murmur of Insanity</p>
                      <p className="drop">
                        <strong>2.03%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    4-21 Hard{' '}
                    <span className="tag maybe">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> A good
                      alternative
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>30.17%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/liquified_terror.webp"
                        alt="Material"
                      />
                      <p className="name">Liquified Terror</p>
                      <p className="drop">
                        <strong>20.95%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/trembling_tooth.webp"
                        alt="Material"
                      />
                      <p className="name">Trembling Tooth</p>
                      <p className="drop">
                        <strong>57.82%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Magnesia Crystal</p>
                      <p className="drop">
                        <strong>70.02%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                3-13 stage is a great place to get Salted Mandrake, but it's
                also a great source of Magnesia - both the Blue and Green
                variants. As for 4-21, it's a decent alternative but the chance
                to obtain Mandrake is a bit lower. So pick your poison based on
                the Blue materials you need!
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/winged_key.webp"
                alt="Material"
              />
              <h5>Winged Key</h5>
            </div>
            <div className="information grid-2">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    5-3 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>27.76%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/esoteric_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Esoteric Bones</p>
                      <p className="drop">
                        <strong>29.81%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/shattered_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Shattered Bones</p>
                      <p className="drop">
                        <strong>121.63%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/silver_ore.webp"
                        alt="Material"
                      />
                      <p className="name">Silver Ore</p>
                      <p className="drop">
                        <strong>71.15%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    4-18{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>16</strong> stamina | <strong>29.78%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/milled_magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Milled Magnesia</p>
                      <p className="drop">
                        <strong>14.68%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Magnesia Crystal</p>
                      <p className="drop">
                        <strong>36.98%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/silver_ore.webp"
                        alt="Material"
                      />
                      <p className="name">Silver Ore</p>
                      <p className="drop">
                        <strong>68.49%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                Winged Key can be farmed in 5-3 Hard stage - while its drop
                chance is a bit lower compared to other purple materials, the
                lower rarity ones have quite a decent drop rate in this node.
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/goose_neck.webp"
                alt="Material"
              />
              <h5>Goose Neck</h5>
            </div>
            <div className="information">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    5-4 Hard{' '}
                    <span className="tag farm">
                      <FontAwesomeIcon icon={faThumbsUp} width="18" /> Worth
                      farming!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>30.35%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/rough_silver_ingot.webp"
                        alt="Material"
                      />
                      <p className="name">Rough Silver Ingot</p>
                      <p className="drop">
                        <strong>54.07%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/liquified_terror.webp"
                        alt="Material"
                      />
                      <p className="name">Liquified Terror</p>
                      <p className="drop">
                        <strong>2.48%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/spell_of_banishing.webp"
                        alt="Material"
                      />
                      <p className="name">Spell of Banishing</p>
                      <p className="drop">
                        <strong>68.58%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                Goose Neck, one of the new materials added in patch 1.4, comes
                with a great node right out of the gate, where you can easily
                farm it!
              </p>
            </div>
          </div>
          <div className="insight-single">
            <div className="material purple">
              <StaticImage
                src="../../../images/reverse/materials/golden_beetle.webp"
                alt="Material"
              />
              <h5>Golden Beetle</h5>
            </div>
            <div className="information grid-2">
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    3-15{' '}
                    <span className="tag yellow">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        width="18"
                      />{' '}
                      Consider crafting!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>14</strong> stamina | <strong>19.85%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/esoteric_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Esoteric Bones</p>
                      <p className="drop">
                        <strong>3.71%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/milled_magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Milled Magnesia</p>
                      <p className="drop">
                        <strong>2.41%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop green">
                      <StaticImage
                        src="../../../images/reverse/materials/shattered_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Shattered Bones</p>
                      <p className="drop">
                        <strong>80.33%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-card">
                <div className="top-info">
                  <p className="stage">
                    5-4{' '}
                    <span className="tag yellow">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        width="18"
                      />{' '}
                      Consider crafting!
                    </span>
                  </p>
                  <p className="drop">
                    <strong>18</strong> stamina | <strong>28.18%</strong> drop
                    chance
                  </p>
                </div>
                <div className="more-drops">
                  <p className="title">Other important drops:</p>
                  <div className="more-drops-inside grid-3">
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/esoteric_bones.webp"
                        alt="Material"
                      />
                      <p className="name">Esoteric Bones</p>
                      <p className="drop">
                        <strong>13.73%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/milled_magnesia.webp"
                        alt="Material"
                      />
                      <p className="name">Milled Magnesia</p>
                      <p className="drop">
                        <strong>0.43%</strong> drop chance
                      </p>
                    </div>
                    <div className="additional-drop blue">
                      <StaticImage
                        src="../../../images/reverse/materials/liquified_terror.webp"
                        alt="Material"
                      />
                      <p className="name">Liquified Terror</p>
                      <p className="drop">
                        <strong>0.72%</strong> drop chance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments">
              <p>
                <strong>Comments:</strong>
              </p>
              <p>
                Well... It had to happen. While the other two new materials have
                great nodes to farm the, Golden Beetle is the opposite, so
                consider crafting it instead (be aware - the material requires
                the dreaded SOLIDUS to craft). Only when Chapter 6 arrives to
                Global will the issue be solved.
              </p>
            </div>
          </div>
        </div>
        <SectionHeader title="Blue tier materials" />
        <p>
          When it comes to the Blue tier materials, you have to make a choice:
        </p>
        <ul>
          <li>
            you farm a stage that has higher drop chance of the material you
            want, but the other drops in that stage aren't that good,
          </li>
          <li>
            you choose lower drop chance for the material in exchange of better
            side-drops - so it translates to better stamina efficiency.
          </li>
        </ul>
        <p>The data in the table below follows this scheme:</p>
        <ul>
          <li>Stage,</li>
          <li>Drop chance,</li>
          <ul>
            <li>
              if a material has 0% drop chance it means you can't drop it
              directly, but you get a good drop chance of its lower tier
              variant. So you farm the lower tier variant and craft the higher
              one,
            </li>
            <li>
              if there's a * mark beside the percentage the sample size is too
              small and the data may not be fully accurate,
            </li>
          </ul>
          <li>Best side-drop available.</li>
        </ul>
        <div className="lower-insight">
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/esoteric_bones.webp"
                alt="Material"
              />
              <h5>Esoteric Bones</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">1-11 Hard</span> |{' '}
                <span className="rate">73.61%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">4-11 Hard</span> |{' '}
                <span className="rate">33.51%</span> |{' '}
                <span className="special">Prophetic Bird</span>
              </p>
              <p>
                <span className="stage">4-20 Hard</span> |{' '}
                <span className="rate">0%</span> |{' '}
                <span className="special">Bifurcated Skeleton</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/liquified_terror.webp"
                alt="Material"
              />
              <h5>Liquified Terror</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">1-14 Hard</span> |{' '}
                <span className="rate">66.71%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">3-7 Hard</span> |{' '}
                <span className="rate">23.24%</span> |{' '}
                <span className="special">Bifurcated Skeleton</span>
              </p>
              <p>
                <span className="stage">4-21 Hard</span> |{' '}
                <span className="rate">20.95%</span> |{' '}
                <span className="special">Salted Mandrake</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/milled_magnesia.webp"
                alt="Material"
              />
              <h5>Milled Magnesia</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">4-5 Hard</span> |{' '}
                <span className="rate">71.15%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">3-13 Hard</span> |{' '}
                <span className="rate">13.15%</span> |{' '}
                <span className="special">Salted Mandrake</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/rough_silver_ingot.webp"
                alt="Material"
              />
              <h5>Rough Silver Ingot</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">3-15 Hard</span> |{' '}
                <span className="rate">108.93%*</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">3-9 Hard</span> |{' '}
                <span className="rate">53.624%</span> |{' '}
                <span className="special">Prophetic Bird</span>
              </p>
              <p>
                <span className="stage">4-10 Hard</span> |{' '}
                <span className="rate">54.37%</span> |{' '}
                <span className="special">Biting Box</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/solidus.webp"
                alt="Material"
              />
              <h5>Solidus</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">3-5 Hard</span> |{' '}
                <span className="rate">46.50%*</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                Directly farming Solidus isn't advised. There's no dedicated
                node that also has Purple materials so it's better to farm the
                following stages for its crafting materials instead: 2-3 and
                2-12.
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/spell_of_fortune.webp"
                alt="Material"
              />
              <h5>Spell of Fortune</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">4-16 Hard</span> |{' '}
                <span className="rate">203.95%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">4-20 Hard</span> |{' '}
                <span className="rate">43.96%</span> |{' '}
                <span className="special">Bifurcated Skeleton</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/perp_cog.webp"
                alt="Material"
              />
              <h5>Perpetual Cog</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>Coming soon!</p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>Coming soon!</p>
            </div>
          </div>
          <div className="single-card">
            <div className="material blue">
              <StaticImage
                src="../../../images/reverse/materials/cicada_wings.webp"
                alt="Material"
              />
              <h5>Cicada Wings</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>Coming soon!</p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>Coming soon!</p>
            </div>
          </div>
        </div>
        <SectionHeader title="Green tier materials" />
        <p>Green tier materials follow the same rules as Blue tier ones.</p>
        <div className="lower-insight">
          <div className="single-card">
            <div className="material green">
              <StaticImage
                src="../../../images/reverse/materials/magnesia.webp"
                alt="Material"
              />
              <h5>Magnesia Crystal</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">2-9 Hard</span> |{' '}
                <span className="rate">100%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">3-13 Hard</span> |{' '}
                <span className="rate">68.82%</span> |{' '}
                <span className="special">Salted Mandrake</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material green">
              <StaticImage
                src="../../../images/reverse/materials/silver_ore.webp"
                alt="Material"
              />
              <h5>Silver Ore</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">2-3 Hard</span> |{' '}
                <span className="rate">167.11%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">4-10 Hard</span> |{' '}
                <span className="rate">70.22%</span> |{' '}
                <span className="special">Biting Box</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material green">
              <StaticImage
                src="../../../images/reverse/materials/shattered_bones.webp"
                alt="Material"
              />
              <h5>Shattered Bones</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">3-8 Hard</span> |{' '}
                <span className="rate">122.64%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">4-11 Hard</span> |{' '}
                <span className="rate">114.36%</span> |{' '}
                <span className="special">Prophetic Bird</span>
              </p>
              <p>
                <span className="stage">4-20 Hard</span> |{' '}
                <span className="rate">65.83%</span> |{' '}
                <span className="special">Bifurcated Skeleton</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material green">
              <StaticImage
                src="../../../images/reverse/materials/spell_of_banishing.webp"
                alt="Material"
              />
              <h5>Spell of Banishing</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">4-20 Hard</span> |{' '}
                <span className="rate">202.55%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">4-20 Hard</span> |{' '}
                <span className="rate">202.55%</span> |{' '}
                <span className="special">Bifurcated Skeleton</span>
              </p>
            </div>
          </div>
          <div className="single-card">
            <div className="material green">
              <StaticImage
                src="../../../images/reverse/materials/trembling_tooth.webp"
                alt="Material"
              />
              <h5>Trembling Tooth</h5>
            </div>
            <div className="title">Highest drop chance:</div>
            <div className="data">
              <p>
                <span className="stage">2-12 Hard</span> |{' '}
                <span className="rate">100%</span>
              </p>
            </div>
            <div className="title">Most efficient:</div>
            <div className="data">
              <p>
                <span className="stage">3-7 Hard</span> |{' '}
                <span className="rate">56.46%</span> |{' '}
                <span className="special">Bifurcated Skeleton</span>
              </p>
              <p>
                <span className="stage">4-21 Hard</span> |{' '}
                <span className="rate">57.82%</span> |{' '}
                <span className="special">Salted Mandrake</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevGuidesInsightCheet;

export const Head: React.FC = () => (
  <Seo
    title="Insight Materials Cheat Sheet | Reverse: 1999 | Prydwen Institute"
    description="Best stages to farm Insight Materials in Reverse: 1999."
    game="reverse"
  />
);
